var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{staticClass:"question single-answer-matrix column is-10",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"question-text"},[(errors[0])?_c('p',{staticClass:"help is-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]),_c('div',{staticClass:"question-content card"},[_c('div',{staticClass:"card-content"},[(!_vm.showMobileView)?_c('div',{staticClass:"is-desktop"},[(_vm.categories)?[_c('table',{staticClass:"table is-hidden-touch block-randomization"},[_c('colgroup',_vm._l((_vm.cols),function(index){return _c('col',{key:index,attrs:{"width":_vm.colWidth(index)}})}),0),_c('tr',{staticClass:"border-0 sticky-header"},[_c('td'),_vm._l((_vm.question.answers),function(answer){return _c('td',{key:answer.id,domProps:{"innerHTML":_vm._s(answer.answer[_vm.lang])}})})],2),_vm._l((_vm.categories),function(category,index){return [(category.display_title && category.sub_questions.length > 0)?_c('tr',{key:("block-" + index),staticClass:"category-title"},[_c('td',{attrs:{"colspan":_vm.question.answers.length + 1}},[_vm._v(_vm._s(category.title[_vm.lang] || Object.values(category.title)[0]))])]):_vm._e(),_vm._l((category.sub_questions),function(subQuestion){return _c('ValidationProvider',{key:subQuestion.id,attrs:{"rules":_vm.validationRules,"tag":"tr"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('td',{class:{ 'has-text-danger has-text-weight-bold': errors[0] }},[_c('div',{domProps:{"innerHTML":_vm._s(subQuestion.question[_vm.lang])}})]),_vm._l((_vm.question.answers),function(answer){return _c('td',{key:answer.id},[_c('b-radio',{attrs:{"disabled":_vm.shouldDisable(_vm.questionKey(_vm.question.qid, subQuestion.code, answer.code)),"native-value":answer.code,"value":_vm.value[_vm.questionKey(_vm.question.qid, subQuestion.code)]},on:{"input":function($event){_vm.onChange(_vm.questionKey(_vm.question.qid, subQuestion.code), $event)}}})],1)})]}}],null,true)})})]})],2)]:[_c('table',{staticClass:"table is-hidden-touch"},[_c('colgroup',_vm._l((_vm.cols),function(index){return _c('col',{key:index,attrs:{"width":_vm.colWidth(index)}})}),0),_c('tr',{staticClass:"sticky-header"},[_c('td'),_vm._l((_vm.question.answers),function(answer){return _c('td',{key:answer.id,domProps:{"innerHTML":_vm._s(answer.answer[_vm.lang])}})})],2),_vm._l((_vm.question.sub_questions),function(subQuestion){return _c('ValidationProvider',{key:subQuestion.id,attrs:{"rules":_vm.validationRules,"tag":"tr"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('td',{class:{ 'has-text-danger has-text-weight-bold': errors[0] }},[_c('div',{domProps:{"innerHTML":_vm._s(subQuestion.question[_vm.lang])}})]),_vm._l((_vm.question.answers),function(answer){return _c('td',{key:answer.id},[_c('b-radio',{attrs:{"disabled":_vm.shouldDisable(_vm.questionKey(_vm.question.qid, subQuestion.code, answer.code)),"native-value":answer.code,"value":_vm.value[_vm.questionKey(_vm.question.qid, subQuestion.code)]},on:{"input":function($event){_vm.onChange(_vm.questionKey(_vm.question.qid, subQuestion.code), $event)}}})],1)})]}}],null,true)})})],2)]],2):_vm._e(),_c('div',{staticClass:"is-mobile",class:{'is-hidden-desktop' : !_vm.showMobileView, 'show-as-mobile-view': _vm.showMobileView}},[(_vm.categories)?_vm._l((_vm.categories),function(category,index){return _c('div',{key:index,staticClass:"category-content"},[(category.display_title && category.sub_questions.length > 0)?_c('p',{staticClass:"category-title"},[_vm._v(_vm._s(category.title[_vm.lang] || Object.values(category.title)[0]))]):_vm._e(),_vm._l((category.sub_questions),function(subQuestion){return _c('b-collapse',{key:("collapse-" + (_vm.getIndex(subQuestion._id))),ref:("collapse" + (_vm.getIndex(subQuestion._id))),refInFor:true,staticClass:"card p-2 mb-2",attrs:{"open":_vm.isOpen === _vm.getIndex(subQuestion._id),"animation":"slide"},on:{"open":function($event){_vm.openCollapse(_vm.getIndex(subQuestion._id))}},scopedSlots:_vm._u([{key:"trigger",fn:function(props){return [_c('div',{staticClass:"card-header",class:{ active: props.open, 'has-error': _vm.hasError(_vm.getIndex(subQuestion._id)) },attrs:{"role":"button"}},[_c('div',{staticClass:"card-header-control"},[_c('p',{staticClass:"card-header-title",domProps:{"innerHTML":_vm._s(subQuestion.question[_vm.lang])}}),_c('a',{staticClass:"card-header-icon"},[_c('b-icon',{attrs:{"icon":props.open ? 'menu-down' : 'menu-up'}})],1)]),(
                          !props.open &&
                            _vm.selectedValue(
                              _vm.value[_vm.questionKey(_vm.question.qid, subQuestion.code)]
                            ) !== ''
                        )?_c('div',{staticClass:"selected-option"},[_c('b-icon',{attrs:{"icon":"check"}}),_c('span',{domProps:{"innerHTML":_vm._s(_vm.selectedValue(_vm.value[_vm.questionKey(_vm.question.qid, subQuestion.code)]))}})],1):_vm._e()])]}}],null,true)},[_c('ValidationProvider',{key:subQuestion.id,ref:("mobileProvider" + (_vm.getIndex(subQuestion._id))),refInFor:true,staticClass:"card-content",attrs:{"rules":_vm.validationRules,"tag":"div"}},[_c('div',{staticClass:"content"},_vm._l((_vm.question.answers),function(answer){return _c('b-field',{key:answer.id,staticClass:"mb-4"},[_c('b-radio',{attrs:{"disabled":_vm.shouldDisable(_vm.questionKey(_vm.question.qid, subQuestion.code, answer.code)),"native-value":answer.code,"value":_vm.value[_vm.questionKey(_vm.question.qid, subQuestion.code)]},on:{"input":function($event){_vm.onChange(
                              _vm.questionKey(_vm.question.qid, subQuestion.code),
                              $event
                            )}}},[_c('span',{domProps:{"innerHTML":_vm._s(answer.answer[_vm.lang])}})])],1)}),1)])],1)})],2)}):_vm._l((_vm.question.sub_questions),function(subQuestion,index){return _c('b-collapse',{key:("collapse-" + index),ref:("collapse" + index),refInFor:true,staticClass:"card p-2 mb-2",attrs:{"open":_vm.isOpen === index,"animation":"slide"},on:{"open":function($event){return _vm.openCollapse(index)}},scopedSlots:_vm._u([{key:"trigger",fn:function(props){return [_c('div',{staticClass:"card-header",class:{ active: props.open, 'has-error': _vm.hasError(index) },attrs:{"role":"button"}},[_c('div',{staticClass:"card-header-control"},[_c('p',{staticClass:"card-header-title",domProps:{"innerHTML":_vm._s(subQuestion.question[_vm.lang])}}),_c('a',{staticClass:"card-header-icon"},[_c('b-icon',{attrs:{"icon":props.open ? 'menu-down' : 'menu-up'}})],1)]),(
                        !props.open &&
                          _vm.selectedValue(
                            _vm.value[_vm.questionKey(_vm.question.qid, subQuestion.code)]
                          ) !== ''
                      )?_c('div',{staticClass:"selected-option"},[_c('b-icon',{attrs:{"icon":"check"}}),_c('span',{domProps:{"innerHTML":_vm._s(_vm.selectedValue(_vm.value[_vm.questionKey(_vm.question.qid, subQuestion.code)]))}})],1):_vm._e()])]}}],null,true)},[_c('ValidationProvider',{key:subQuestion.id,ref:("mobileProvider" + index),refInFor:true,staticClass:"card-content",attrs:{"rules":_vm.validationRules,"tag":"div"}},[_c('div',{staticClass:"content"},_vm._l((_vm.question.answers),function(answer){return _c('b-field',{key:answer.id,staticClass:"mb-4"},[_c('b-radio',{attrs:{"disabled":_vm.shouldDisable(_vm.questionKey(_vm.question.qid, subQuestion.code, answer.code)),"native-value":answer.code,"value":_vm.value[_vm.questionKey(_vm.question.qid, subQuestion.code)]},on:{"input":function($event){_vm.onChange(
                            _vm.questionKey(_vm.question.qid, subQuestion.code),
                            $event
                          )}}},[_c('span',{domProps:{"innerHTML":_vm._s(answer.answer[_vm.lang])}})])],1)}),1)])],1)})],2)])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }